import React, { useEffect, useState } from 'react'
import Main from '../Main/Main'
import RealEstate from '../RealEstate/RealEstate'
import Equipment from '../Equipment/Equipment'
import Strategies from '../Strategies/Strategies'
import Contacts from '../Contacts/Contacts'
import { useSelector } from 'react-redux'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'
import { translateData } from './translateData'
import { IMAGES } from '../../images/images'
import Tarifs from '../Tarifs/Tarifs'

function ServicesPage() {
    const {currentLang} = useSelector(selectAppInfo)
    const [textData, setTextData] = useState({})

    useEffect(() => {
        if (currentLang.id) {
            if(textData.code !== currentLang.code) {
                setTextData(...translateData?.filter(el => el.code === currentLang.code))
            }
        }
    }, [currentLang, textData])

    return (
        <>
            <Main textData={textData?.main} images={{img1: IMAGES.mainImg1, img2: IMAGES.mainImg2}} />
            <RealEstate  textData={textData?.realEstate} img={IMAGES.realEstateImg2}/>
            <Equipment textData={textData?.equipment} />
            <Strategies textData={textData?.strategies} images={{img1: IMAGES.strategiesImg4, img2: IMAGES.strategiesImg2, img3: IMAGES.strategiesImg5}} />
            <Contacts textData={textData?.contatcs} />
        </>
    )
}

export default ServicesPage
