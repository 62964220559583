import React, { useEffect, useState } from 'react'
import RealEstate from '../RealEstate/RealEstate'
import Equipment from '../Equipment/Equipment'
import Strategies from '../Strategies/Strategies'
import Contacts from '../Contacts/Contacts'
import { useSelector } from 'react-redux'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'
import { translateData } from './translateData'
import { IMAGES } from '../../images/images'
import DiscoverMain from '../DiscoverMain/DiscoverMain'
import Services from '../Services/Services'
import TaxAdvice from '../TaxAdvice/TaxAdvice'
import Languages from '../Languages/Languages'
import Banner from '../Banner/Banner'
import Cases from '../Cases/Cases'
import Tarifs from '../Tarifs/Tarifs'

function DiscoverPage() {
    const {currentLang} = useSelector(selectAppInfo)
    const [textData, setTextData] = useState({})

    useEffect(() => {
        if (currentLang.id) {
            if(textData.code !== currentLang.code) {
                setTextData(...translateData?.filter(el => el.code === currentLang.code))
            }
        }
    }, [currentLang, textData])

    return (
        <>
            <DiscoverMain textData={textData?.main} images={{img1: IMAGES.mainImg3, img2: IMAGES.mainImg4}} />
            <RealEstate  textData={textData?.realEstate} img={IMAGES.realEstateImg4}/>
            <Services textData={textData?.services} />
            <TaxAdvice textData={textData?.taxAdvice} />
            <Languages textData={textData?.languages} />
            <Strategies textData={textData?.strategies} images={{img1: IMAGES.strategiesImg6, img2: IMAGES.strategiesImg2, img3: IMAGES.strategiesImg3}} line={true} />
            <Banner img={IMAGES.banner} />
            <Equipment textData={textData?.equipment} />
            <Cases textData={textData?.cases} />
            <Contacts textData={textData?.contatcs} />
        </>
    )
}

export default DiscoverPage
