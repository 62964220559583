import React from 'react'
import { FinanceIcon, PlaningIcon } from '../../images/svg'

function Strategies({textData, images, line}) {
    return (
        <section className="strategies" id='services'>
            <div className="strategies__container container">
                <div className="strategies__flex">
                    <div className="strategies__img">
                        <img src={images.img1} alt="img" />
                        <h2>{textData?.title}</h2>
                    </div>
                    <div className="strategies__right">
                        <div className="strategies__img">
                            <img src={images.img2} alt="img" />
                        </div>
                        <div className="strategies__item">
                            <div className="icon"><PlaningIcon /></div>
                            <p>{textData?.item1}</p>
                        </div>
                        <div className="strategies__item">
                            <div className="icon"><FinanceIcon /></div>
                            <p>{textData?.item2}</p>
                        </div>
                        <div className="strategies__img">
                            <img src={images.img3} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
            {
                !line && (
                    <div className="strategies__line">
                        <p>{textData?.stroke[0]}<span> {textData?.stroke[1]}</span></p>
                    </div>
                ) 
            }
        </section>
    )
}

export default Strategies
