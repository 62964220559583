import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'
import { translateData } from './translateData'
import { Link } from 'react-router-dom'

function CanclePayment() {
    const { currentLang } = useSelector(selectAppInfo)
    const [textData, setTextData] = useState({})
    useEffect(() => {
        if (currentLang?.id && textData?.code !== currentLang.code) {
            const newTextData = translateData.find(el => el.code === currentLang.code)
            if (newTextData) {
                setTextData(newTextData)
            }
        }
    }, [currentLang, textData])
  return (
    <section className='confirmation'>
        <div className="confirmation__container container">
            <h1>{textData?.title}</h1>
            <p>{textData?.desc}</p>
            <Link to={'/prices'}>{textData?.btnText}</Link>
        </div>
    </section>
  )
}

export default CanclePayment
