import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import HomeWrapper from './pages/HomeWrapper';
import HomPage from './components/HomPage/HomPage';
import ServicesPage from './components/ServicesPage/ServicesPage';
import AboutPage from './components/AboutPage/AboutPage';
import PricesPage from './components/PricesPage/PricesPage';
import DiscoverPage from './components/DiscoverPage/DiscoverPage';
import RegistrationPage from './components/RegistrationPage/RegistrationPage';
import LoginPage from './components/LoginPage/LoginPage';
import Confirmation from './components/Confirmation/Confirmation';
import MessagesWrapper from './pages/MessagesWrapper';
import CanclePayment from './components/CanclePayment/CanclePayment';
import RememberPassPage from './components/RememberPassPage/RememberPassPage';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppInfo, toggleLang } from './store/appInfoSlice/appInfoSlice';
import { useEffect, useState } from 'react';
import LegalPage from './components/LegalPage/LegalPage';
import TermsPage from './components/TermsPage/TermsPage';
import CookiesPage from './components/CookiesPgae/CookiesPage';
import ErrorPage from './pages/ErrorPage';
import ServicesSinglPage from './components/ServicesSinglPage/ServicesSinglPage';

function App() {
  const { currentLang, langData } = useSelector(selectAppInfo);
  const [getLang, setGetLang] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pathname, setPathname] = useState('');

  useEffect(() => {
    window.scrollTo(0,0)
    setPathname(window.location.pathname)
    let pathLang = pathname.slice(pathname.indexOf('/') + 1, pathname.indexOf('/') + 3);
    if (pathLang && pathLang !== "") {
      if (langData.some(el => el.code === pathLang)) {
        dispatch(toggleLang({ ...langData.filter(el => el.code === pathLang)[0] }));
      } else {
        navigate(`/${currentLang.code}${pathname}`);
      }
      setGetLang(true);
    }
    if(pathname === '/') {
      navigate(`/${currentLang.code}/`);
    }
  }, [getLang, window.location.pathname, pathname]);

  return (
    <div className="wrapper">
      <Routes>
        <Route path={`/${currentLang?.code}/`} element={<HomeWrapper />}>
          <Route index element={<DiscoverPage />} />
          <Route path='consulta-financiera' element={<HomPage />} />
          <Route path="asesoramiento-inmobiliario" element={<ServicesPage />} />
          <Route path="servicio-de-idiomas" element={<AboutPage />} />
          <Route path="prices" element={<PricesPage />} />
          <Route path="registration" element={<RegistrationPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="rememberPass" element={<RememberPassPage />} />
          <Route path="legal" element={<LegalPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="cookies" element={<CookiesPage />} />
          <Route path='services' element={<ServicesSinglPage />} />
        </Route>
        <Route path={`/${currentLang?.code}/messagges`} element={<MessagesWrapper />}>
          <Route path="confirmation" element={<Confirmation />} />
          <Route path="cancel-payment" element={<CanclePayment />} />
        </Route>
        <Route path='*' element={<ErrorPage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
