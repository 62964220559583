import React, { useEffect, useRef, useState } from 'react'
import { ArrowRight, FbIcon, InstaIcon, LinkdinIcon, TwitterIcon } from '../../images/svg'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'
import { translateData } from './translateData'
import { IMAGES } from '../../images/images'
import axios from 'axios'
import Loader from '../Loader/Loader'

function Footer() {
    const [textData, setTextData] = useState({})
    const { currentLang } = useSelector(selectAppInfo)
    const subscribeRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (currentLang.id) {
            if (textData.code !== currentLang.code) {
                setTextData(...translateData?.filter(el => el.code === currentLang.code))
            }
        }
    }, [currentLang, textData])
    return (
        <>
            {isLoading && <Loader />}
            <footer className="footer">
                <div className="footer__top">
                    <div className="footer__container container">
                        <div className="footer__left">
                            <a href="/" className="logo"><img src={IMAGES.footerLogo} alt="logo" /></a>
                            <p>{textData?.desc}</p>
                            <form className="footer__subscribe" ref={subscribeRef} onSubmit={(e) => {
                                e.preventDefault()
                                setIsLoading(true)
                                axios.post(`http://176.97.114.121/api/subscribers`, {
                                    email: subscribeRef.current[0].value
                                }).then(res => {
                                    setIsLoading(false)
                                }).catch(res => {
                                    setIsLoading(false)
                                })
                            }}>
                                <label htmlFor className="input-text">{textData?.subscribeText}
                                    <input type="text" placeholder="EMAIL" />
                                    <ArrowRight color={'#998861'} />
                                </label>
                            </form>
                        </div>
                        <div className="footer__rigth">
                            <div className="footer__menu">
                                {
                                    textData?.items?.map(el => (
                                        <div className="footer__menu-item" key={el.id}>
                                            <h4>{el.title}</h4>
                                            <ul>
                                                {
                                                    el.menu.map(link => (
                                                        <li key={link.id}><NavLink to={link.href}>{link.text}</NavLink></li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="footer__soc">
                                <p>{textData?.footerSoc}</p>
                                <a href="https://www.linkedin.com/in/nordic-connect-22b239320/" target='_blank'><LinkdinIcon /></a>
                                <a href><FbIcon /></a>
                                <a href="https://x.com/ConectNord29477" target='_blank'><TwitterIcon /></a>
                                <a href><InstaIcon /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom">
                    <div className="footer__container container">
                        <p>{textData?.address}</p>
                        <p>{textData?.footerBottom}</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
