import React from 'react'
import ProductItem from '../ProductItem/ProductItem'

function Products({ textData }) {
    return (
        <section className="equipment">
            <div className="equipment__container container">
                <div className='equipment__flex'>
                    <div className="equipment__left">
                        <div className='equipment__info'>
                            <h3>{textData?.title[0]}<span>{textData?.title[1]}</span></h3>
                            <p>{textData?.desc}</p>
                        </div>
                    </div>
                </div>
                <div className='equipment__products' id='about-products'>
                    {
                        textData?.items.map(el => (
                            <ProductItem key={el.id} productItem={el} />
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default Products
