import { createSlice } from "@reduxjs/toolkit";

// Функция для загрузки состояния из localStorage
const loadState = () => {
    try {
        const serializedState = localStorage.getItem("currentUser");
        return serializedState ? JSON.parse(serializedState) : {};
    } catch (e) {
        console.warn("Не удалось загрузить состояние из localStorage", e);
        return {};
    }
};

// Функция для сохранения состояния в localStorage
const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("currentUser", serializedState);
    } catch (e) {
        console.warn("Не удалось сохранить состояние в localStorage", e);
    }
};

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        currentUser: loadState(),
        userToken: "",
        registrationData: {},
    },
    reducers: {
        toggleRegData: (state, { payload }) => {
            state.registrationData = {
                ...state.registrationData,
                [payload.key]: payload.value
            };
        },

        login: (state, { payload }) => {
            state.currentUser = {
                ...payload
            };
            saveState(state.currentUser); 
        },

        setUserToken: (state, {payload}) => {
            state.userToken = payload.token
            console.log(payload);
        }
    }
});

export const selectUsers = state => state.users;
export const { toggleRegData, login, setUserToken } = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
