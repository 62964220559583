import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Footer/Footer';

function HomeWrapper() {
    const location = useLocation();
    const [isMenuActive, setIsMenuActive] = useState(false);

    useEffect(() => {
        const activeMenuPaths = ['legal', 'terms', 'cookies', 'prices', 'login', 'registration', 'rememberPass'];
        const path = location.pathname.split('/').pop();
        setIsMenuActive(activeMenuPaths.includes(path));
    }, [location.pathname]);

    return (
        <>
            <Header menu={isMenuActive} />
            <main className='page'>
                <Outlet />
            </main>
            <Footer />
        </>
    );
}

export default HomeWrapper;
