import React from 'react'
import { ArrowRight } from '../../images/svg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'

function Languages({ textData }) {
    const {currentLang} = useSelector(selectAppInfo)
    return (
        <section className="languages">
            <div className="languages__container container">
                <div className='languages__flex'>
                    <div className='languages__right'>
                        <div className="languages__grid">
                            {
                                textData?.items.map(el => (
                                    <div className="languages__item" key={el.id}>
                                        <span>{el.num}</span>
                                        <h4>{el.title}</h4>
                                        <p>{el.desc}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="languages__left">
                        <div className="languages__map">
                            <span>{textData?.subTitle}</span>
                            <h2>{textData?.title}</h2>
                            <p>{textData?.desc}</p>
                        </div>
                        <Link to={`/${currentLang.code}/servicio-de-idiomas`} className="button">
                            {textData?.btnText}
                            <ArrowRight color={"#fff"} />
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Languages
