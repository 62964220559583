import React from 'react'
import { ArrowRight } from '../../images/svg'
import { Link } from 'react-router-dom'

function Main({ textData, images }) {
    return (
        <section className="main" id='main'>
            <div className="main__container container">
                <div className="main__bg">
                    <div className="main__bg-item">
                        <img src={images.img1} alt="img" />
                    </div>
                    <div className="main__bg-item" style={{ background: '#D7C08B' }} />
                    <div className="main__bg-item" style={{ background: '#C8820C' }} />
                    <div className="main__bg-item">
                        <img src={images.img2} alt='img' />
                    </div>
                </div>
                <div className="main__text">
                    <h1>{textData?.title[0]} <span>{textData?.title[1]}</span></h1>
                    {
                        textData?.btnText && (
                            <a href="#about" className="button">
                                {textData?.btnText}
                                <ArrowRight color={"#fff"} />
                            </a>
                        )
                    }
                </div>
            </div>
        </section>
    )
}

export default Main
