import React, { useCallback, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import { SlideNextIcon, SlidePrevIcon } from '../../images/svg';

function Cases({ textData }) {
    const casesSwiperRef = useRef(null)

    const prevSlide = useCallback(() => {
        casesSwiperRef.current?.swiper.slidePrev();
    }, [casesSwiperRef]);

    const nextSlide = useCallback(() => {
        casesSwiperRef.current?.swiper.slideNext();
    }, [casesSwiperRef]);

    return (
        <section className="cases">
            <div className="cases__container container">
                <div className="cases__title">
                    <div className="text">
                        <h2>{textData?.title}<span>{textData?.subTitle}</span></h2>
                        <p>{textData?.desc}</p>
                    </div>
                    <div className="cases__buttons">
                        <button onClick={() => prevSlide()}><SlidePrevIcon /></button>
                        <button onClick={() => nextSlide()}><SlideNextIcon /></button>
                    </div>
                </div>
                <div className="cases__swiper">
                    <Swiper ref={casesSwiperRef} spaceBetween={32} slidesPerView={'auto'}>
                        {
                            textData?.items?.map(el => (
                                <SwiperSlide key={el.id}>
                                    <div className="cases__item">
                                        <div className="img">
                                            <img src={el.img} alt="img" />
                                        </div>
                                        <div className="text">
                                            <span>{el.subTitle}</span>
                                            <h4>{el.title}</h4>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Cases
