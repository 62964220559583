import React, { useEffect, useState } from 'react'
import Contacts from '../Contacts/Contacts'
import { useSelector } from 'react-redux'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'
import { translateData } from './translateData'
import { useNavigate } from 'react-router-dom'
import { selectUsers } from '../../store/usersSlice/usersSlice'
import LineBlock from '../LineBlock/LineBlock'
import Login from '../Login/Login'

function LoginPage() {
    const { currentLang } = useSelector(selectAppInfo)
    const { currentUser } = useSelector(selectUsers)
    const [textData, setTextData] = useState({})
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser?.id) {
            navigate('/prices')
        }
    }, [currentUser, navigate])

    useEffect(() => {
        if (currentLang?.id && textData?.code !== currentLang.code) {
            const newTextData = translateData.find(el => el.code === currentLang.code)
            if (newTextData) {
                setTextData(newTextData)
            }
        }
    }, [currentLang, textData])

    return (
        <>
            <Login textData={textData?.registration} />
            <LineBlock textData={textData?.line} />
            <Contacts textData={textData?.contacts} />
        </>
    )
}

export default LoginPage
