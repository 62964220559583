import React from 'react'

function LineBlock({textData}) {
    return (
        <div className="strategies__line">
            <p>{textData?.text[0]}<span> {textData?.text[1]}</span></p>
        </div>
    )
}

export default LineBlock
