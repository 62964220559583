export const translateData = [
    {
        code: 'gb',
        prices: {
            title: ['A Rate that Suits', 'Your Needs'],
            infoTitle: ['Choose Your Rate ', '40% Discount'],
            desc: 'Choose the right workspace plan',
            btnText: 'Get Started Now',
            items: [
                {
                    id: '1',
                    price: '217.8€ <span>/Tax advice</span>',
                    info: [
                        {
                            id: '1',
                            text: 'VAT included',
                        }
                    ]
                },
                {
                    id: '2',
                    price: '605€ <span>/Financial Consultancy</span>',
                    info: [
                        {
                            id: '1',
                            text: 'VAT included',
                        }
                    ]
                },
                {
                    id: '3',
                    price: '907.5€ <span>/Real Estate Advice</span>',
                    info: [
                        {
                            id: '1',
                            text: 'VAT included',
                        }
                    ]
                },
                {
                    id: '4',
                    price: '145.2€<span>/Language Services</span>',
                    info: [
                        {
                            id: '1',
                            text: 'VAT included',
                        }
                    ]
                }
            ]
        },
        strategies: {
            title: 'Real Estate Investment Advice',
            item1: 'Maximize Your Return in the Global Real Estate Market',
            item2: 'Customized Investment Strategies for Real Estate Success',
            stroke: ['Connecting Your Global Opportunities: Advice on', 'tax, languages, investments, and finance.']
        },
        contatcs: {
            title: 'Hello! Let\'s connect and create something great together. How about we start by saying hello?',
            phoneInput: 'Your Name',
            noteInput: 'Your Message',
            btnText: 'Send Message'
        }
    },
    {
        code: 'sv',
        prices: {
            title: ['A Rate that Suits', 'Your Needs'],
            infoTitle: ['Choose Your Rate ', '40% Discount'],
            desc: 'Choose the right workspace plan',
            btnText: 'Get Started Now',
            items: [
                {
                    id: '1',
                    price: '217.8€ <span>/Skatterådgivning</span>',
                    info: [
                        {
                            id: '1',
                            text: 'IVA inkluderar',
                        }
                    ]
                },
                {
                    id: '2',
                    price: '605€ <span>/Finansiell Rådgivning</span>',
                    info: [
                        {
                            id: '1',
                            text: 'IVA inkluderar',
                        }
                    ]
                },
                {
                    id: '3',
                    price: '907.5€ <span>/Fastighetsrådgivning</span>',
                    info: [
                        {
                            id: '1',
                            text: 'IVA inkluderar',
                        }
                    ]
                },
                {
                    id: '4',
                    price: '145.2€<span>/Språk Tjänster</span>',
                    info: [
                        {
                            id: '1',
                            text: 'IVA inkluderar',
                        }
                    ]
                }
            ]
        },
        strategies: {
            title: 'Real Estate Investment Advice',
            item1: 'Maximize Your Return in the Global Real Estate Market',
            item2: 'Customized Investment Strategies for Real Estate Success',
            stroke: ['Connecting Your Global Opportunities: Advice on', 'tax, languages, investments, and finance.']
        },
        contatcs: {
            title: 'Hello! Let\'s connect and create something great together. How about we start by saying hello?',
            phoneInput: 'Your Name',
            noteInput: 'Your Message',
            btnText: 'Send Message'
        }
    },
    {
        code: 'es',
        prices: {
            title: ['Una Tarifa que se adapta', 'a tus necesidades'],
            infoTitle: ['Elije tu tarifa ', '40% Descuento'],
            desc: 'Elege el plan de espacio de trabajo adecuado ',
            btnText: 'Empieza ahora',
            items: [
                {
                    id: '1',
                    price: '217.8€ <span>/Fiscal</span>',
                    info: [
                        {
                            id: '1',
                            text: 'IVA incluido',
                        }
                    ]
                },
                {
                    id: '2',
                    price: '605€ <span>/Consulta Financiera</span>',
                    info: [
                        {
                            id: '1',
                            text: 'IVA incluido',
                        }
                    ]
                },
                {
                    id: '3',
                    price: '907.5€ <span>/Asesoramiento Inmobiliario</span>',
                    info: [
                        {
                            id: '1',
                            text: 'IVA incluido',
                        }
                    ]
                },
                {
                    id: '4',
                    price: '145.2€<span>/Servicio de Idiomas</span>',
                    info: [
                        {
                            id: '1',
                            text: 'IVA incluido',
                        }
                    ]
                }
            ]
        },
        strategies: {
            title: 'Asesoramiento en Inversiones Inmobiliarias',
            item1: 'Maximiza su Retorno en el Mercado Inmobiliario Global',
            item2: 'Estrategias de Inversión Personalizadas para el Éxito Inmobiliario',
            stroke: ['Conectando tus oportunidades globales: Asesoramiento', 'fiscal, idiomas, inversiones y finanzas. ']
        },
        contatcs: {
            title: '¡Hola! Conectemos y creemos algo grandioso juntos. ¿Qué tal si empiezamos diciendonos hola?',
            phoneInput: 'Su nombre',
            noteInput: 'Su mensaje',
            btnText: 'Enviar mensaje'
        }
    },
]