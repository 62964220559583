import React from 'react'
import { ArrowRight } from '../../images/svg'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../store/usersSlice/usersSlice'
import { Link } from 'react-router-dom'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'

function Services({ textData }) {
    const {userToken} = useSelector(selectUsers)
    const {currentLang} = useSelector(selectAppInfo)
    return (
        <section className="services">
            <div className="services__container container">
                <div className='services__flex'>
                    <div className="services__left">
                        <div className="services__map">
                            <span>{textData?.subTitle}</span>
                            <h2>{textData?.title}</h2>
                            <p>{textData?.desc}</p>
                        </div>
                        <Link to={userToken ? `/${currentLang.code}/prices` : `/${currentLang.code}/login`} className="button">
                            {textData?.btnText}
                            <ArrowRight color={"#fff"} />
                        </Link>
                    </div>
                    <div className='services__right'>
                        <div className="services__grid">
                            {
                                textData?.items.map(el => (
                                    <div className="services__item" key={el.id}>
                                        <el.Icon />
                                        <h4>{el.title}</h4>
                                        <p>{el.desc}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services
