import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { Link } from 'react-router-dom'

function ErrorPage() {
    return (
        <>
            <Header />
            <main className='page'>
                <section className='error'>
                    <div className="error__container container">
                        <h1>Error 404</h1>
                        <Link to={'/'} className='button'>Back to home</Link>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default ErrorPage
