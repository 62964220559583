import React, { useRef, useState } from 'react'
import { ArrowRight } from '../../images/svg'
import Loader from '../Loader/Loader'
import axios from 'axios'

function Contacts({ textData }) {
    const contactsRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            {
                isLoading && <Loader />
            }
            <section className="contacts" id='contacts'>
                <div className="contacts__container container">
                    <h2>{textData?.title}</h2>
                    <form ref={contactsRef} onSubmit={(e) => {
                        e.preventDefault()
                        setIsLoading(true)
                        axios.post(`https://nordicconnect.pro/api/email/contact-use`, {
                            phone: contactsRef.current[1].value,
                            passportNum: contactsRef.current[2].value,
                            email: contactsRef.current[0].value
                        }).then(res => {
                            setIsLoading(false)
                        }).catch(res => {
                            setIsLoading(false)
                        })
                    }}>
                        <div className="flex">
                            <label htmlFor className="input-text">
                                <input type="text" placeholder="Email" required />
                            </label>
                            <label htmlFor className="input-text">
                                <input type="text" placeholder={textData?.phoneInput} required />
                            </label>
                        </div>
                        <label htmlFor className="input-text">
                            <input type="text" placeholder={textData?.noteInput} required />
                        </label>
                        <div className="contacts__submit">
                            <button className="button" type='submit'>
                                {textData?.btnText}
                                <ArrowRight color={"#FFF"} />
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Contacts
