import { IMAGES } from "../../images/images";

export const translateData = [
    {
        code: 'gb',

        main: {
            title: ['Consulting on', 'International Tax'],
            btnText: 'Discover more'
        },

        products: {
            title: ['Our', 'Best Positions'],
            desc: 'International tax advice encompasses a wide range of services designed to help businesses navigate the complex international tax landscape. From cross-border tax planning to resolving international tax disputes, experts in this field work closely with their clients to develop customized strategies that align with their business objectives and comply with local and international tax regulations.',
            items: [
                {
                    id: '1',
                    img: IMAGES.homeImg,
                    title: 'Urban Property Investment Opportunity',
                    text: 'This three-story urban house offers an excellent investment opportunity in a prime location. With modern design and high-quality finishes, this property is ideal for long-term rentals or Airbnb.',
                    price: 'Free',
                    btnText: 'CHECK AVAILABILITY'
                },
                {
                    id: '2',
                    img: IMAGES.homeImg,
                    title: 'Investment in Country House with Rural Tourism Potential',
                    text: 'This charming country house on a large plot offers an excellent investment opportunity for rural tourism. With a peaceful environment and stunning views, it is perfect for those looking to escape the hustle and bustle of the city.',
                    price: '145.2€',
                    btnText: 'CHECK AVAILABILITY'
                },
                {
                    id: '3',
                    img: IMAGES.homeImg,
                    title: 'Investment in Modern Property with High Rental Demand',
                    text: 'This contemporary house, built with high-quality materials and equipped with modern amenities, is an excellent investment option for residential rentals. Its attractive design and convenient location make it highly sought after by potential tenants.',
                    price: 'Personalized consultation',
                    btnText: 'CHECK AVAILABILITY'
                },
            ]
        },

        realEstate: {
            title: 'Simplify the complexity of tax regulations',
            subTitle: 'ABOUT US',
            text1: 'In today’s business environment, international expansion is more than a possibility: it’s a necessity for growth and survival. However, this process comes with a series of complex tax challenges that can complicate the efficient operation of companies in multiple jurisdictions. This is where international tax consulting plays a crucial role, providing strategic guidance to maximize benefits and minimize tax liabilities in a global context.',
            text2: 'International tax consulting encompasses a wide range of services designed to help companies navigate the complex international tax landscape. From cross-border tax planning to resolving international tax disputes, experts in this field work closely with their clients to develop customized strategies that align with their business objectives and comply with local and international tax regulations.',
        },

        strategies: {
            title: 'Real Estate Investment Consulting',
            item1: 'Maximizing Your Return in the Global Real Estate Market',
            item2: 'Experts in Personalized Investment Strategies for Real Estate Success',
            stroke: ['Connecting your global opportunities: Tax consulting,', 'languages, investments, and finance.']
        },

        contatcs: {
            title: 'Hello! Let’s connect and create something great together. How about starting by saying hello?',
            phoneInput: 'Your name',
            noteInput: 'Your message',
            btnText: 'Send message'
        }
    },
    {
        code: 'sv',
        main: {
            title: ['Rådgivning om', 'Internationell Skatt'],
            btnText: 'Upptäck mer'
        },

        products: {
            title: ['Våra', 'Bästa Positioner'],
            desc: 'Internationell skatterådgivning omfattar ett brett utbud av tjänster utformade för att hjälpa företag att navigera det komplexa internationella skatteklimatet. Från gränsöverskridande skatteplanering till lösning av internationella skattetvister, arbetar experter inom detta område nära sina kunder för att utveckla skräddarsydda strategier som stämmer överens med deras affärsmål och följer lokala och internationella skatteregler.',
            items: [
                {
                    id: '1',
                    img: IMAGES.homeImg,
                    title: 'Investering i Urban Egendomsmöjlighet',
                    text: 'Detta trevåningshus i urban stil erbjuder en utmärkt investeringsmöjlighet på en förmånlig plats. Med modern design och högkvalitativa ytor är denna fastighet idealisk för långtidsuthyrning eller Airbnb.',
                    price: 'Fri',
                    btnText: 'KOLLA TILLGÄNGLIGHET'
                },
                {
                    id: '2',
                    img: IMAGES.homeImg,
                    title: 'Investering i Landsbygdshus med Potential för Landsbygdsturism',
                    text: 'Detta charmiga landsbygdshus på en stor tomt erbjuder en utmärkt investeringsmöjlighet för landsbygdsturism. Med en fridfull miljö och imponerande utsikt är det perfekt för dem som vill undkomma stadens brus.',
                    price: '145.2€',
                    btnText: 'KOLLA TILLGÄNGLIGHET'
                },
                {
                    id: '3',
                    img: IMAGES.homeImg,
                    title: 'Investering i Modern Fastighet med Hög Efterfrågan på Uthyrning',
                    text: 'Detta samtida hus, byggt med högkvalitativa material och utrustat med moderna bekvämligheter, är ett utmärkt investeringsalternativ för bostadsuthyrning. Dess attraktiva design och bekväma läge gör det mycket efterfrågat av potentiella hyresgäster.',
                    price: 'Personlig konsultation',
                    btnText: 'KOLLA TILLGÄNGLIGHET'
                },
            ]
        },

        realEstate: {
            title: 'Förenkla komplexiteten i skatteregler',
            subTitle: 'OM OSS',
            text1: 'I dagens affärsmiljö är internationell expansion mer än en möjlighet: det är en nödvändighet för tillväxt och överlevnad. Men denna process kommer med en serie komplexa skatteutmaningar som kan komplicera företagens effektiva drift i flera jurisdiktioner. Det är här internationell skatterådgivning spelar en avgörande roll, genom att ge strategisk vägledning för att maximera fördelar och minimera skatteförpliktelser i ett globalt sammanhang.',
            text2: 'Internationell skatterådgivning omfattar ett brett spektrum av tjänster utformade för att hjälpa företag att navigera i det komplexa internationella skattelandskapet. Från gränsöverskridande skatteplanering till att lösa internationella skattetvister, experter inom detta område arbetar nära sina kunder för att utveckla skräddarsydda strategier som är i linje med deras affärsmål och uppfyller lokala och internationella skatteregler.',
        },
        strategies: {
            title: 'Rådgivning om Fastighetsinvesteringar',
            item1: 'Maximera din avkastning på den globala fastighetsmarknaden',
            item2: 'Experter på Personliga Investeringsstrategier för Fastighetsframgång',
            stroke: ['Koppla dina globala möjligheter: Skatterådgivning,', 'språk, investeringar och finans.']
        },
        contatcs: {
            title: 'Hej! Låt oss ansluta och skapa något fantastiskt tillsammans. Vad sägs om att börja med att säga hej?',
            phoneInput: 'Ditt namn',
            noteInput: 'Ditt meddelande',
            btnText: 'Skicka meddelande'
        }
    },
    {
        code: 'es',

        main: {
            title: ['Asesoramiento en', 'Fiscal Internacional'],
            btnText: 'Descubre mas'
        },

        realEstate: {
            title: 'Simplifica la complejidad de las normativas fiscales ',
            subTitle: 'SOBRE NOSOTROS',
            text1: 'En el entorno empresarial actual, la expansión internacional es más que una posibilidad: es una necesidad para el crecimiento y la supervivencia. Sin embargo, este proceso conlleva una serie de desafíos fiscales complejos que pueden complicar la operación eficiente de las empresas en múltiples jurisdicciones. Es aquí donde el asesoramiento fiscal internacional desempeña un papel crucial, brindando orientación estratégica para maximizar los beneficios y minimizar las obligaciones fiscales en un contexto global.',
            text2: 'El asesoramiento fiscal internacional abarca una amplia gama de servicios diseñados para ayudar a las empresas a navegar por el complejo panorama fiscal internacional. Desde la planificación fiscal transfronteriza hasta la resolución de disputas fiscales internacionales, los expertos en este campo trabajan en estrecha colaboración con sus clientes para desarrollar estrategias personalizadas que se alineen con sus objetivos comerciales y cumplan con las regulaciones fiscales locales e internacionales.',
        },

        strategies: {
            title: 'Asesoramiento en Inversiones Inmobiliarias',
            item1: 'Maximizando su Retorno en el Mercado Inmobiliario Global',
            item2: 'Expertos en Estrategias de Inversión Personalizadas para el Éxito Inmobiliario',
            stroke: ['Conectando tus oportunidades globales: Asesoramiento', 'fiscal, idiomas, inversiones y finanzas. ']
        },

        products: {
            title: ['Nuestras', 'mejores posiciones'],
            desc: 'El asesoramiento fiscal internacional abarca una amplia gama de servicios diseñados para ayudar a las empresas a navegar por el complejo panorama fiscal internacional. Desde la planificación fiscal transfronteriza hasta la resolución de disputas fiscales internacionales, los expertos en este campo trabajan en estrecha colaboración con sus clientes para desarrollar estrategias personalizadas que se alineen con sus objetivos comerciales y cumplan con las regulaciones fiscales locales e internacionales.',
            items: [
                {
                    id: '1',
                    img: IMAGES.homeImg,
                    title: 'Oportunidad de Inversión en Propiedad Urbana',
                    text: 'Esta casa urbana de tres pisos ofrece una excelente oportunidad de inversión en una ubicación privilegiada. Con un diseño moderno y acabados de alta calidad, esta propiedad es ideal para alquileres de largo plazo o para Airbnb.',
                    price: 'Consulta',
                    btnText: 'CONSULTAR DISPONIBLIDAD '
                },
                {
                    id: '2',
                    img: IMAGES.homeImg,
                    title: 'Inversión en Casa de Campo con Potencial de Turismo Rural',
                    text: 'Esta encantadora casa de campo en una parcela extensa ofrece una excelente oportunidad de inversión para el turismo rural. Con un entorno tranquilo y vistas impresionantes, es perfecta para aquellos que buscan escapar del bullicio de la ciudad.',
                    price: '145.2€',
                    btnText: 'CONSULTAR DISPONIBLIDAD '
                },
                {
                    id: '3',
                    img: IMAGES.homeImg,
                    title: 'Inversión en Propiedad Moderna con Alta Demanda de Alquiler',
                    text: 'Esta casa contemporánea, construida con materiales de alta calidad y equipada con comodidades modernas, es una excelente opción de inversión en alquileres residenciales. Su diseño atractivo y ubicación conveniente la hacen muy solicitada por inquilinos potenciales.',
                    price: 'Consulta personalizada',
                    btnText: 'CONSULTAR DISPONIBLIDAD '
                },
            ]
        },

        contatcs: {
            title: '¡Hola! Conectemos y creemos algo grandioso juntos. ¿Qué tal si empiezamos diciendonos hola?',
            phoneInput: 'Su nombre',
            noteInput: 'Su mensaje',
            btnText: 'Enviar mensaje'
        }
    },
]