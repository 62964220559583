import { createSlice } from "@reduxjs/toolkit";

export const appInfoSlice = createSlice({
    name: 'appInfoSlice',
    initialState: {
        currentLang: {
            id: '3',
            code: 'es'
        },
        langData: [
            {
                id: '1',
                code: 'gb'
            },
            {
                id: '2',
                code: 'sv'
            },
            {
                id: '3',
                code: 'es'
            },
        ]
    },
    reducers: {
        toggleLang: (state, {payload}) => {
            state.currentLang = {...payload}
        }
    }
})

export const selectAppInfo = state => state.appInfoSlice
export const {toggleLang} = appInfoSlice.actions
export const appInfoReducer = appInfoSlice.reducer