import React from 'react'
import parse from 'html-react-parser';

function InfoBlock({type, textData}) {
  return (
    <section className='info-block'>
      <div className="info-block__container container">
        {
            type === 'legal' ? (
                <>
                    {
                        textData?.items?.map(el => (
                            <div className="info-block__item" key={el.id}>
                                <h3>{el.title}</h3>
                                <p>{parse(el.desc)}</p>
                            </div>
                        ))
                    }
                </>
            ) : type === 'terms' ? (
                <>
                    <h1>{textData?.title}</h1>
                    {textData?.desc && parse(textData?.desc)}
                </>
            ) : null
        }
      </div>
    </section>
  )
}

export default InfoBlock
