import React from 'react'
import { ArrowRight } from '../../images/svg'
import { IMAGES } from '../../images/images'
import ProductItem from '../ProductItem/ProductItem'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../store/usersSlice/usersSlice'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'

function Equipment({ textData }) {
    const {userToken} = useSelector(selectUsers)
    const {currentLang} = useSelector(selectAppInfo)
    return (
        <section className="equipment">
            <div className="equipment__container container">
                <div className='equipment__flex'>
                    <div className="equipment__left">
                        <div className="equipment__map">
                            <span>{textData?.subTitle}</span>
                            <h2>{textData?.title}</h2>
                            <p>{textData?.desc}</p>
                            <img src={IMAGES.mapImg} alt="img" />
                        </div>
                        {
                            textData?.info ? (
                                <div className='equipment__info'>
                                    <h3>{textData.info.title[0]}<span>{textData.info.title[1]}</span></h3>
                                    <p>{textData.info.desc}</p>
                                </div>
                            ) : (
                                textData?.btnText &&
                                <Link to={userToken ? `/${currentLang.code}/prices` : `/${currentLang.code}/login`} className="button">
                                    {textData?.btnText}
                                    <ArrowRight color={"#fff"} />
                                </Link>
                            )
                        }
                    </div>
                    <div className='equipment__right'>
                        <div className="equipment__grid">
                            {
                                textData?.items.map(el => (
                                    <div className="equipment__item" key={el.id}>
                                        <span>{el.num}</span>
                                        <h4>{el.title}</h4>
                                        <p>{el.desc}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {
                    textData?.info && (
                        <div className='equipment__products' id='about-products'>
                            {
                                textData.info.items.map(el => (
                                    <ProductItem key={el.id} productItem={el} />
                                ))
                            }
                        </div>
                    )
                }
            </div>
        </section>
    )
}

export default Equipment
