import React from 'react'

function Banner({img}) {
  return (
    <div className='banner'>
        <img src={img} alt="img" />
    </div>
  )
}

export default Banner
