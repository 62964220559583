import { IMAGES } from "../../images/images";

export const translateData = [
    {
        code: 'gb',

        main: {
            title: ['Financial Advice', 'Comprehensive Financial Consultation'],
            btnText: 'Discover More'
        },

        realEstate: {
            title: 'Comprehensive Financial Consultation Service',
            subTitle: 'REAL ESTATE',
            text1: 'We offer a comprehensive financial consultation service designed to meet all your financial needs. Our team of financial consulting experts is prepared to guide you through every stage of the process, ensuring that you make informed and accurate decisions.',
            text2: 'Our mission is to help you make sound financial decisions and achieve your financial goals. Contact us today to schedule a consultation and find out how we can help you secure your financial future.',
            btnText: 'Discover Our Rates'
        },

        equipment: {
            title: 'EU Countries Coverage',
            subTitle: 'EQUIPMENT',
            desc: 'We have a network of tax experts in over 10 countries, which allows us to provide advice with a deep understanding of local laws and regulations.',
            info: {
                title: ['Our', 'Best Positions'],
                desc: 'International tax advice encompasses a wide range of services designed to help businesses navigate the complex international tax landscape. From cross-border tax planning to resolving international tax disputes, experts in this field work closely with their clients to develop customized strategies that align with their business objectives and comply with local and international tax regulations.',
                items: [
                    {
                        id: '1',
                        img: IMAGES.homeImg,
                        title: 'Urban Property Investment Opportunity',
                        text: 'This three-story urban house offers an excellent investment opportunity in a prime location. With modern design and high-quality finishes, this property is ideal for long-term rentals or Airbnb.',
                        price: 'Free',
                        btnText: 'CHECK AVAILABILITY'
                    },
                    {
                        id: '2',
                        img: IMAGES.homeImg,
                        title: 'Investment in Country House with Rural Tourism Potential',
                        text: 'This charming country house on a large plot offers an excellent investment opportunity for rural tourism. With a peaceful environment and stunning views, it is perfect for those looking to escape the hustle and bustle of the city.',
                        price: '605€',
                        btnText: 'CHECK AVAILABILITY'
                    },
                    {
                        id: '3',
                        img: IMAGES.homeImg,
                        title: 'Investment in Modern Property with High Rental Demand',
                        text: 'This contemporary house, built with high-quality materials and equipped with modern amenities, is an excellent investment option for residential rentals. Its attractive design and convenient location make it highly sought after by potential tenants.',
                        price: 'Personalized consultation',
                        btnText: 'CHECK AVAILABILITY'
                    },
                ]
            },
            items: [
                {
                    id: '1',
                    num: '01',
                    title: 'Holistic Financial Planning',
                    desc: 'We develop comprehensive financial plans tailored to your short-term and long-term financial goals. Our financial advisors work closely with you to assess your current financial situation, identify financial goals, and create a detailed plan to achieve them. Whether you are looking to buy a house, save for retirement, or plan for succession, we are here to help you chart a clear path to financial stability.'
                },
                {
                    id: '2',
                    num: '02',
                    title: 'Wealth Management',
                    desc: 'We offer wealth management services designed to help you manage and grow your wealth over time. Our expert financial advisors will assist you in building a diversified, personalized investment portfolio, considering your financial goals, risk tolerance, and time horizon. From asset selection to ongoing portfolio monitoring, we are committed to maximizing your wealth and protecting your financial interests.'
                },
                {
                    id: '3',
                    num: '03',
                    title: 'Tax Planning Advice',
                    desc: 'Our tax experts will help you optimize your tax situation and legally minimize your tax burden. We provide personalized tax planning strategies that will allow you to make the most of available tax-saving opportunities and meet all tax obligations. From personal tax planning to business tax structuring, we are here to help you navigate the complex tax landscape with confidence and peace of mind.'
                },
                {
                    id: '4',
                    num: '04',
                    title: 'Business Financial Consulting',
                    desc: 'We offer business financial consulting services to help companies achieve their financial goals and improve their financial performance. Our financial experts work closely with business leaders to analyze company finances, identify areas for improvement, and develop effective financial strategies. Whether you need help with cash flow management, strategic financial planning, or project viability analysis, we are here to provide the support and guidance you need to achieve financial success.'
                },
            ]
        },

        strategies: {
            title: 'Wealth Protection Strategies',
            item1: 'Retirement Planning',
            item2: 'Advice on Financing and Loans',
            stroke: ['Connecting your global opportunities: Financial advice, languages, investments, and finance.']
        },

        contatcs: {
            title: 'Hello! Let’s connect and create something great together. How about we start by saying hello?',
            phoneInput: 'Your Name',
            noteInput: 'Your Message',
            btnText: 'Send Message'
        }
    },
    {
        code: 'sv',

        main: {
            title: ['Finansiell Rådgivning', 'Omfattande Finansiell Konsultation'],
            btnText: 'Utforska Mer'
        },

        realEstate: {
            title: 'Omfattande Finansiell Konsultationstjänst',
            subTitle: 'FASTIGHETER',
            text1: 'Vi erbjuder en omfattande finansiell konsultationstjänst som är utformad för att möta alla dina finansiella behov. Vårt team av finansiella konsulter är redo att vägleda dig genom varje steg av processen och säkerställa att du fattar informerade och korrekta beslut.',
            text2: 'Vårt uppdrag är att hjälpa dig att fatta kloka finansiella beslut och nå dina finansiella mål. Kontakta oss idag för att boka en konsultation och ta reda på hur vi kan hjälpa dig att säkra din finansiella framtid.',
            btnText: 'Upptäck Våra Priser'
        },

        equipment: {
            title: 'Täckning i EU Länder',
            subTitle: 'UTRUSTNING',
            desc: 'Vi har ett nätverk av skatteexperter i över 10 länder, vilket gör att vi kan erbjuda rådgivning med djup förståelse för lokala lagar och regler.',
            info: {
                title: ['Våra', 'Bästa Positioner'],
                desc: 'Internationell skatterådgivning omfattar ett brett utbud av tjänster utformade för att hjälpa företag att navigera det komplexa internationella skatteklimatet. Från gränsöverskridande skatteplanering till lösning av internationella skattetvister, arbetar experter inom detta område nära sina kunder för att utveckla skräddarsydda strategier som stämmer överens med deras affärsmål och följer lokala och internationella skatteregler.',
                items: [
                    {
                        id: '1',
                        img: IMAGES.homeImg,
                        title: 'Investering i Urban Egendomsmöjlighet',
                        text: 'Detta trevåningshus i urban stil erbjuder en utmärkt investeringsmöjlighet på en förmånlig plats. Med modern design och högkvalitativa ytor är denna fastighet idealisk för långtidsuthyrning eller Airbnb.',
                        price: 'Fri',
                        btnText: 'KOLLA TILLGÄNGLIGHET'
                    },
                    {
                        id: '2',
                        img: IMAGES.homeImg,
                        title: 'Investering i Landsbygdshus med Potential för Landsbygdsturism',
                        text: 'Detta charmiga landsbygdshus på en stor tomt erbjuder en utmärkt investeringsmöjlighet för landsbygdsturism. Med en fridfull miljö och imponerande utsikt är det perfekt för dem som vill undkomma stadens brus.',
                        price: '605€',
                        btnText: 'KOLLA TILLGÄNGLIGHET'
                    },
                    {
                        id: '3',
                        img: IMAGES.homeImg,
                        title: 'Investering i Modern Fastighet med Hög Efterfrågan på Uthyrning',
                        text: 'Detta samtida hus, byggt med högkvalitativa material och utrustat med moderna bekvämligheter, är ett utmärkt investeringsalternativ för bostadsuthyrning. Dess attraktiva design och bekväma läge gör det mycket efterfrågat av potentiella hyresgäster.',
                        price: 'Personlig konsultation',
                        btnText: 'KOLLA TILLGÄNGLIGHET'
                    },
                ]
            },
            items: [
                {
                    id: '1',
                    num: '01',
                    title: 'Helhetsinriktad Finansiell Planering',
                    desc: 'Vi utvecklar omfattande finansiella planer som är anpassade till dina kort- och långsiktiga finansiella mål. Våra finansiella rådgivare arbetar nära dig för att bedöma din nuvarande ekonomiska situation, identifiera finansiella mål och skapa en detaljerad plan för att uppnå dem. Oavsett om du vill köpa ett hus, spara till pensionen eller planera för arv, är vi här för att hjälpa dig att skapa en tydlig väg till finansiell stabilitet.'
                },
                {
                    id: '2',
                    num: '02',
                    title: 'Förmögenhetsförvaltning',
                    desc: 'Vi erbjuder tjänster för förmögenhetsförvaltning som är utformade för att hjälpa dig att hantera och växa din förmögenhet över tid. Våra expertfinansiella rådgivare hjälper dig att bygga en diversifierad och personlig investeringsportfölj, med hänsyn till dina finansiella mål, risktolerans och tidsram. Från val av tillgångar till kontinuerlig portföljövervakning är vi engagerade i att maximera din förmögenhet och skydda dina finansiella intressen.'
                },
                {
                    id: '3',
                    num: '03',
                    title: 'Skatteplaneringsrådgivning',
                    desc: 'Våra skatteexperter hjälper dig att optimera din skattesituation och lagligt minimera din skattebörda. Vi tillhandahåller personliga skatteplaneringsstrategier som gör det möjligt för dig att utnyttja tillgängliga skattebesparande möjligheter och uppfylla alla skattepliktar. Från personlig skatteplanering till företagsstrukturering av skatter, är vi här för att hjälpa dig navigera i det komplexa skatteklimatet med förtroende och sinnesro.'
                },
                {
                    id: '4',
                    num: '04',
                    title: 'Affärsfinansiell Konsultation',
                    desc: 'Vi erbjuder finansiella konsulttjänster för företag för att hjälpa dem att nå sina finansiella mål och förbättra sin ekonomiska prestation. Våra finansiella experter arbetar nära företagsledare för att analysera företagets ekonomi, identifiera förbättringsområden och utveckla effektiva finansiella strategier. Oavsett om du behöver hjälp med kassaflödeshantering, strategisk finansiell planering eller projektvärdering, är vi här för att ge det stöd och den vägledning du behöver för att uppnå finansiell framgång.'
                },
            ]
        },

        strategies: {
            title: 'Strategier för Förmögenhetsskydd',
            item1: 'Pensionsplanering',
            item2: 'Rådgivning om Finansiering och Lån',
            stroke: ['Koppla samman dina globala möjligheter: Finansiell rådgivning, språk, investeringar och finans.']
        },

        contatcs: {
            title: 'Hej! Låt oss koppla ihop oss och skapa något fantastiskt tillsammans. Vad säger du om att börja med att säga hej?',
            phoneInput: 'Ditt Namn',
            noteInput: 'Ditt Meddelande',
            btnText: 'Skicka Meddelande'
        }
    },
    {
        code: 'es',

        main: {
            title: ['Asesoramiento en', 'Consulta Financiera Integral'],
            btnText: 'Descubre mas'
        },

        realEstate: {
            title: 'Servicio de Consulta Financiera Integral',
            subTitle: 'REAL ESTATE',
            text1: 'ofrecemos un servicio de consulta financiera integral diseñado para satisfacer todas sus necesidades financieras. Nuestro equipo de expertos en consultoría financiera está preparado para guiarlo en cada etapa del proceso, asegurando que tome decisiones informadas y acertadas.',
            text2: 'Nuestra misión es ayudarlo a tomar decisiones financieras sólidas y alcanzar sus metas financieras. Contáctenos hoy para programar una consulta y descubra cómo podemos ayudarlo a asegurar su futuro financiero.',
            btnText: 'Descubre nuestras tarifas'
        },

        equipment: {
            title: 'EURO Países de Cobertura',
            subTitle: 'EQUIPO',
            desc: 'Contamos con una red de expertos fiscales en más de 50 países, lo que nos permite ofrecer asesoría con conocimiento profundo de las leyes y regulaciones locales.',
            info: {
                title: ['Nuestras', 'mejores posiciones'],
                desc: 'El asesoramiento fiscal internacional abarca una amplia gama de servicios diseñados para ayudar a las empresas a navegar por el complejo panorama fiscal internacional. Desde la planificación fiscal transfronteriza hasta la resolución de disputas fiscales internacionales, los expertos en este campo trabajan en estrecha colaboración con sus clientes para desarrollar estrategias personalizadas que se alineen con sus objetivos comerciales y cumplan con las regulaciones fiscales locales e internacionales.',
                items: [
                    {
                        id: '1',
                        img: IMAGES.homeImg,
                        title: 'Oportunidad de Inversión en Propiedad Urbana',
                        text: 'Esta casa urbana de tres pisos ofrece una excelente oportunidad de inversión en una ubicación privilegiada. Con un diseño moderno y acabados de alta calidad, esta propiedad es ideal para alquileres de largo plazo o para Airbnb.',
                        price: 'Consulta',
                        btnText: 'CONSULTAR DISPONIBLIDAD '
                    },
                    {
                        id: '2',
                        img: IMAGES.homeImg,
                        title: 'Inversión en Casa de Campo con Potencial de Turismo Rural',
                        text: 'Esta encantadora casa de campo en una parcela extensa ofrece una excelente oportunidad de inversión para el turismo rural. Con un entorno tranquilo y vistas impresionantes, es perfecta para aquellos que buscan escapar del bullicio de la ciudad.',
                        price: '605€',
                        btnText: 'CONSULTAR DISPONIBLIDAD '
                    },
                    {
                        id: '3',
                        img: IMAGES.homeImg,
                        title: 'Inversión en Propiedad Moderna con Alta Demanda de Alquiler',
                        text: 'Esta casa contemporánea, construida con materiales de alta calidad y equipada con comodidades modernas, es una excelente opción de inversión en alquileres residenciales. Su diseño atractivo y ubicación conveniente la hacen muy solicitada por inquilinos potenciales.',
                        price: 'Consulta personalizada',
                        btnText: 'CONSULTAR DISPONIBLIDAD '
                    },
                ]
            },
            items: [
                {
                    id: '1',
                    num: '01',
                    title: 'Planificación Financiera Holística',
                    desc: 'Desarrollamos planes financieros integrales adaptados a sus objetivos financieros a corto y largo plazo. Nuestros asesores financieros trabajarán estrechamente con usted para evaluar su situación financiera actual, identificar metas financieras y crear un plan detallado para alcanzarlas. Ya sea que esté buscando comprar una casa, ahorrar para la jubilación o planificar su sucesión, estamos aquí para ayudarlo a trazar un camino claro hacia la estabilidad financiera.'
                },
                {
                    id: '2',
                    num: '02',
                    title: 'Gestión de Patrimonio',
                    desc: 'Ofrecemos servicios de gestión de patrimonio diseñados para ayudarlo a administrar y hacer crecer su riqueza a lo largo del tiempo. Nuestros asesores financieros expertos lo ayudarán a construir una cartera de inversiones diversificada y personalizada, teniendo en cuenta sus objetivos financieros, tolerancia al riesgo y horizonte temporal. Desde la selección de activos hasta el monitoreo continuo de su cartera, estamos comprometidos en maximizar su patrimonio y proteger sus intereses financieros.'
                },
                {
                    id: '3',
                    num: '03',
                    title: 'Asesoramiento en Planificación Fiscal',
                    desc: 'Nuestros expertos en impuestos lo ayudarán a optimizar su situación fiscal y minimizar su carga tributaria legalmente. Proporcionamos estrategias de planificación fiscal personalizadas que le permitirán aprovechar al máximo las oportunidades de ahorro fiscal disponibles y cumplir con todas las obligaciones fiscales. Desde la planificación de impuestos personales hasta la estructuración fiscal empresarial, estamos aquí para ayudarlo a navegar el complejo panorama fiscal con confianza y tranquilidad.'
                },
                {
                    id: '4',
                    num: '04',
                    title: 'Consultoría en Finanzas Empresariales',
                    desc: 'Ofrecemos servicios de consultoría en finanzas empresariales para ayudar a las empresas a alcanzar sus objetivos financieros y mejorar su desempeño financiero. Nuestros expertos en finanzas trabajan en estrecha colaboración con los líderes empresariales para analizar las finanzas de la empresa, identificar áreas de mejora y desarrollar estrategias financieras efectivas. Ya sea que necesite ayuda con la gestión de efectivo, la planificación financiera estratégica o el análisis de viabilidad de proyectos, estamos aquí para brindarle el apoyo y la orientación que necesita para alcanzar el éxito financiero.'
                },
            ]
        },

        strategies: {
            title: 'Estrategias de Protección Patrimonial',
            item1: 'Planificación de Jubilación',
            item2: 'Asesoramiento en Financiamiento y Préstamos',
            stroke: ['Conectando tus oportunidades globales: Asesoramiento', 'fiscal, idiomas, inversiones y finanzas. ']
        },

        contatcs: {
            title: '¡Hola! Conectemos y creemos algo grandioso juntos. ¿Qué tal si empiezamos diciendonos hola?',
            phoneInput: 'Su nombre',
            noteInput: 'Su mensaje',
            btnText: 'Enviar mensaje'
        }
    },
]