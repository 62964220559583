import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'
import { translateData } from './translateData'
import { ArrowBottom } from '../../images/svg'

function Navigation() {
    const [textData, setTextData] = useState({})
    const { currentLang } = useSelector(selectAppInfo)

    useEffect(() => {
        if (currentLang.id) {
            if (textData.code !== currentLang.code) {
                setTextData(...translateData?.filter(el => el.code === currentLang.code))
            }
        }
    }, [currentLang, textData])
    return (
        <div className="navigation">
            <div className="navigation__container container">
                <ul>
                    {
                        textData?.menu?.map(el => (
                            <li>
                                <NavLink key={el.id} to={el.href}>
                                    {el.text}
                                    <ArrowBottom />
                                </NavLink>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Navigation
