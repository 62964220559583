import React, { useEffect, useState } from 'react'
import Main from '../Main/Main'
import Contacts from '../Contacts/Contacts'
import { useSelector } from 'react-redux'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'
import { translateData } from './translateData'
import { IMAGES } from '../../images/images'
import InfoBlock from '../InfoBlock/InfoBlock'

function TermsPage() {
    const {currentLang} = useSelector(selectAppInfo)
    const [textData, setTextData] = useState({})

    useEffect(() => {
        if (currentLang.id) {
            if(textData.code !== currentLang.code) {
                setTextData(...translateData?.filter(el => el.code === currentLang.code))
            }
        }
    }, [currentLang, textData])

    return (
        <>
            <Main textData={textData?.main} images={{img1: IMAGES.mainImg3, img2: IMAGES.mainImg4}} />
            <InfoBlock type={'terms'} textData={textData?.infoData} />
            <Contacts textData={textData?.contatcs} />
        </>
    )
}

export default TermsPage
