import React, { useEffect, useRef, useState } from 'react';
import { BurgerIcon, EsFlag, GbFlag, SvFlag } from '../../images/svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppInfo, toggleLang } from '../../store/appInfoSlice/appInfoSlice';
import { translateData } from './translateData';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IMAGES } from '../../images/images';
import HeaderMenu from './HeaderMenu/HeaderMenu';
import { selectUsers } from '../../store/usersSlice/usersSlice';

function Header({menu}) {
    const { currentLang } = useSelector(selectAppInfo);
    const { userToken } = useSelector(selectUsers)
    const location = useLocation();
    const navigate = useNavigate();
    const [textData, setTextData] = useState({});
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(false);
    const [clickTimeout, setClickTimeout] = useState(null);

    // Реф для отслеживания области меню
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const changeLang = (id, code) => {
        dispatch(toggleLang({ id: id, code: code }));
        const pathname = location.pathname.slice(4);
        navigate(`/${code}/${pathname}`);
    };

    useEffect(() => {
        if (currentLang.id) {
            if (textData.code !== currentLang.code) {
                setTextData(...translateData?.filter(el => el.code === currentLang.code));
            }
        }
        setToggle(false);
    }, [currentLang, textData]);

    useEffect(() => {
        // Обработчик клика по документу
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setToggle(false);
            }
        };

        // Добавляем обработчик
        document.addEventListener('mousedown', handleClickOutside);

        // Удаляем обработчик при размонтировании
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleButtonClick = () => {
        // Устанавливаем таймер, чтобы избежать проблемы с быстрым закрытием и открытием меню
        if (clickTimeout) {
            clearTimeout(clickTimeout);
        }

        const timeout = setTimeout(() => {
            setToggle(prev => !prev);
        }, 0);

        setClickTimeout(timeout);
    };

    return (
        <header className="header">
            <div className="header__container container">
                <Link to="/" className="logo">
                    <img src={IMAGES.logo} alt="logo" />
                </Link>
                <ul>
                    {!menu && textData?.navigation?.map(el => (
                        <li key={el.id}>
                            <a href={el.href} to={el.href}>{el.title}</a>
                        </li>
                    ))}
                </ul>
                <div className="header__right">
                    {
                        userToken ? (
                            <a href='/' className="search-btn">Logout</a>
                        ) : (
                            <NavLink to={"/login"} className="search-btn">{textData?.searchBtn}</NavLink>
                        )
                    }
                    <button className="butger-btn" onClick={handleButtonClick} ref={buttonRef}>
                        <BurgerIcon />
                    </button>
                    <div className="langs">
                        <button onClick={() => changeLang('1', 'gb')} className={currentLang.code === "gb" ? "active" : ""}>
                            <GbFlag />
                        </button>
                        <button onClick={() => changeLang('2', 'sv')} className={currentLang.code === "sv" ? "active" : ""}>
                            <SvFlag />
                        </button>
                        <button onClick={() => changeLang('3', 'es')} className={currentLang.code === "es" ? "active" : ""}>
                            <EsFlag />
                        </button>
                        <span>{textData?.langBtn}</span>
                    </div>
                </div>
            </div>
            <div ref={menuRef}>
                <HeaderMenu toggle={toggle} />
            </div>
        </header>
    );
}

export default Header;
