
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { IMAGES } from '../../images/images'
import { ArrowRight } from '../../images/svg'
import Loader from '../Loader/Loader'
import { Link } from 'react-router-dom'
import ReactInputMask from 'react-input-mask'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'

function RememberPass({ textData }) {
    const [isLoading, setIsLoading] = useState(false)
    const [rememberEtaps, setRememberEtaps] = useState(1)
    const rememberRef = useRef(null)
    const {currentLang} = useSelector(selectAppInfo)
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (rememberEtaps === 1) {
            axios.post(`https://nordicconnect.pro/api/auth/forgot-password`, {
                email: rememberRef.current[0].value
            }).then(res => {
                setIsLoading(false)
                console.log(res);
                setRememberEtaps(2)
            })
        } else if (rememberEtaps === 2) {
            axios.post(`https://nordicconnect.pro/api/auth/recover-password`, {
                newPassword: rememberRef.current[1].value,
                confirmPassword: rememberRef.current[2].value
            }, {
                headers: {
                    Bearer: `Token ${rememberRef.current[0].value}`
                }
            }).then(res => {
                setIsLoading(false)
                setRememberEtaps(3)
            })
        }
    }

    return (
        <>
            {
                isLoading ? (<Loader />) : (
                    <section className="registration">
                        <div className="registration__container container">
                            <div className="registration__form">
                                <div className="registration__text">
                                    <p className='subtitle'>{textData?.subTitle[0]} <span>{textData?.subTitle[1]}</span> </p>
                                    <h2>{textData?.title}</h2>
                                    <p>{textData?.desc}</p>
                                </div>
                                <form onSubmit={handleSubmit} ref={rememberRef}>
                                    {
                                        rememberEtaps === 1 ? (
                                            <label className="input-text">
                                                <input type="text" name='email' />
                                            </label>
                                        ) : rememberEtaps === 2 && (
                                            <>
                                                <label className="input-text">
                                                    <ReactInputMask type="text" name='code' mask={'9999'} placeholder='_ _ _ _' style={{ textAlign: 'center' }} />
                                                </label>
                                                <label className="input-text">
                                                    <input type="password" name='pass' />
                                                </label>
                                                <label className="input-text">
                                                    <input type="password" name='confirme_pass' />
                                                </label>
                                            </>
                                        )
                                    }

                                    <div className="registration__buttons">
                                        <label className="input-submit">
                                            <input type="submit" value={textData?.input17} />
                                        </label>
                                    </div>
                                </form>
                                <div className="registration__bottom">
                                    <Link to={`/${currentLang.code}/login`}>{textData?.link[0]} <span>{textData?.link[1]}</span> <ArrowRight color={"#C99E06"} /></Link>
                                </div>
                            </div>
                            <div className="registration__img">
                                <img src={IMAGES.strategiesImg1} alt="img"  />
                            </div>
                        </div>
                    </section>
                )
            }
        </>
    )
}

export default RememberPass
