import React, { useEffect, useState } from 'react'
import { CheckIcon } from '../../images/svg'
import parse from 'html-react-parser';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectUsers } from '../../store/usersSlice/usersSlice';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice';

function Tarifs({ textData }) {
    const [isLoading, setIsLoading] = useState(false)
    const {userToken} = useSelector(selectUsers)
    const {currentLang} = useSelector(selectAppInfo)
    const navigate = useNavigate()
    
    return (
        <>
            {isLoading && <Loader />}
            <section className='tarifs'>
                <div className="tarifs__container container">
                    <h1>{textData?.title[0]}<span>{textData?.title[1]}</span></h1>
                    <div className="tarifs__grid">
                        {
                            textData?.items.map((el, idx) => (
                                <div className="tarifs__item" key={el.id}>
                                    <div className="tarifs__item-title">
                                        <h4>{el.price ? parse(el.price) : ''}</h4>
                                        <button onClick={() => {
                                            if(userToken) {
                                                setIsLoading(true)
                                                axios.post(`https://nordicconnect.pro/api/payment`, {
                                                    price: Number(el.price.slice(0, el.price.indexOf('€')))
                                                }, {
                                                    headers: {
                                                        authorization: `Bearer ${userToken}`
                                                    }
                                                }).then(res => {
                                                    setIsLoading(false)
                                                    window.location.href = res.data;
                                                }).catch(res => {
                                                    setIsLoading(false)
                                                })
                                            }else {
                                                navigate(`/${currentLang.code}/login`)
                                            }
                                        }}>{textData?.btnText}</button>
                                    </div>
                                    {
                                        el.info.map(info => (
                                            <div className="tarifs__item-info" key={info.id}>
                                                {info.text && info.text !== "" && <p>{parse(info.text)}</p>}
                                                {
                                                    info.icons && (
                                                        <div className="flex">
                                                            {
                                                                info.icons.map(icon => (
                                                                    <CheckIcon key={icon.id} />
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tarifs;
