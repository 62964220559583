import React from 'react'
import { ArrowRight } from '../../images/svg'
import { Link } from 'react-router-dom'

function RealEstate({textData, img}) {
    return (
        <section className="real-estate" id='about'>
            <div className="real-estate__container container">
                <div className="real-estate__img">
                    <img src={img} alt="img" />
                </div>
                <div className="real-estate__text">
                    <span>{textData?.subTitle}</span>
                    <h2>{textData?.title}</h2>
                    <p>{textData?.text1}</p>
                    <p>{textData?.text2}</p>
                    {
                        textData?.btnText && (
                            <a href={'#about-products'} className="button">
                                {textData?.btnText}
                                <ArrowRight color={"#FFF"} />
                            </a>
                        )
                    }
                </div>
            </div>
        </section>
    )
}

export default RealEstate
