import { Form, Formik } from 'formik'
import * as yup from 'yup'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUsers, setUserToken, toggleRegData } from '../../store/usersSlice/usersSlice'
import axios from 'axios'
import { IMAGES } from '../../images/images'
import { ArrowRight } from '../../images/svg'
import Loader from '../Loader/Loader'
import { Link, useNavigate } from 'react-router-dom'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'

function Login({ textData }) {
    const [isLoading, setIsLoading] = useState(false)
    const {currentLang} = useSelector(selectAppInfo)
    const navigate = useNavigate()
    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email('')
            .required(''),
        password: yup
            .string()
            .required(''),
    })

    const { registrationData } = useSelector(selectUsers)
    const dispatch = useDispatch()

    return (
        <>
            {
                isLoading ? (<Loader />) : (
                    <section className="registration">
                        <div className="registration__container container">
                            <div className="registration__form">
                                <div className="registration__text">
                                    <p className='subtitle'>{textData?.subTitle[0]} <span>{textData?.subTitle[1]}</span> </p>
                                    <h2>{textData?.title}</h2>
                                    <p>{textData?.desc}</p>
                                </div>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        email: registrationData?.email || '',
                                        password: '',
                                    }}
                                    onSubmit={async (values, { resetForm }) => {
                                        setIsLoading(true)
                                        await axios.post(`https://nordicconnect.pro/api/auth/login`, {
                                            email: values.email,
                                            password: values.password
                                        }).then(res => {
                                            navigate('/prices')
                                            dispatch(setUserToken({ token: res.data.access_token }))
                                            setIsLoading(false)
                                        }).catch(res => {
                                            setIsLoading(false)
                                        })
                                    }}
                                    validateOnBlur
                                    validationSchema={validationSchema}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    }) => (
                                        <Form autoComplete="off" onSubmit={handleSubmit}>
                                            <label className="input-text">
                                                <input
                                                    type="email"
                                                    className="input"
                                                    name="email"
                                                    autoComplete="off"
                                                    value={values.email}
                                                    style={{
                                                        border:
                                                            errors.email && touched.email
                                                                ? '1px solid #DC4747'
                                                                : 'unset',
                                                    }}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder={textData?.input4}
                                                />
                                            </label>
                                            <label className="input-text">
                                                <Link to={`/${currentLang.code}/rememberPass`}>{textData?.rememberPass}</Link>
                                                <input
                                                    type="password"
                                                    className="input"
                                                    name="password"
                                                    autoComplete="off"
                                                    value={values.password}
                                                    style={{
                                                        border:
                                                            errors.password && touched.password
                                                                ? '1px solid #DC4747'
                                                                : 'unset',
                                                    }}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder={textData?.input6}
                                                />
                                            </label>
                                            <div className="registration__buttons">
                                                <button type="submit">{textData?.input17}</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                                <div className="registration__bottom">
                                    <Link to={`/${currentLang.code}/registration`}>{textData?.link[0]} <span>{textData?.link[1]}</span> <ArrowRight color={"#C99E06"} /></Link>
                                </div>
                            </div>
                            <div className="registration__img">
                                <img src={IMAGES.realEstateImg3} alt="" img />
                            </div>
                        </div>
                    </section>
                )
            }
        </>
    )
}

export default Login
