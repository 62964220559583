import { IMAGES } from "../../images/images"

function TaxAdvice({textData}) {
  return (
    <section className="tax-advice">
      <div className="tax-advice__container container">
        <div className="tax-advice__left">
            <div className="img">
                <img src={IMAGES.taxImg1} alt="img" />
            </div>
            <div className="text">
                {
                    textData?.left?.map(el => (
                        <div className="tax-advice__left-item" key={el.id}>
                            <div className="info">
                                <span>{el.num}</span>
                                <p>{el.subTitle}</p>
                            </div>
                            <p>{el.text}</p>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className="tax-advice__right">
            <div className="text">
                <h2>{textData?.right.title}</h2>
                <p>{textData?.right.desc}</p>
            </div>
            <div className="img">
                <img src={IMAGES.taxImg2} alt="img" />
            </div>
        </div>
      </div>
    </section>
  )
}

export default TaxAdvice
