import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // localStorage для web
import { appInfoReducer } from './appInfoSlice/appInfoSlice';
import { usersReducer } from './usersSlice/usersSlice';

// Настраиваем конфигурацию для persist
const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    appInfoSlice: appInfoReducer,
    users: usersReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;
