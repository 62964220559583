import React from 'react'
import { ArrowRight } from '../../images/svg'
import { IMAGES } from '../../images/images'

function DiscoverMain({ textData, images }) {
    return (
        <section className="discover-main" id='main'>
            <div className="discover-main__container container">
                <div className="discover-main__bg">
                    <div className="discover-main__bg-item">
                        <img src={images.img1} alt="img" />
                    </div>
                    <div className="discover-main__bg-item" style={{ background: '#D7C08B' }} />
                    <div className="discover-main__bg-item">
                        <img src={IMAGES.mainImg5} alt='img' />
                    </div>
                    <div className="discover-main__bg-item">
                        <img src={images.img2} alt='img' />
                    </div>
                </div>
                <div className="discover-main__text">
                    <h1>{textData?.title[0]} <span>{textData?.title[1]}</span></h1>
                    <a href="#about" className="button">
                        {textData?.btnText}
                        <ArrowRight color={"#fff"} />
                    </a>
                </div>
            </div>
        </section>
    )
}

export default DiscoverMain
