import React from 'react'
import Header from '../components/Header/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer/Footer'

function MessagesWrapper() {
    return (
        <>
            <Header />
            <main className='page'>
                <Outlet />
            </main>
        </>
    )
}

export default MessagesWrapper
