import React from 'react'
import Navigation from '../../Navigation/Navigation'

function HeaderMenu({toggle}) {
  return (
    <div className='header__menu' style={{width: toggle ? "70vw" : "0", overflow: "hidden"}}>
        <div className="header__menu-box">
            <p>Menu</p>
            <Navigation />
        </div>
    </div>
  )
}

export default HeaderMenu
