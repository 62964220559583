import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectUsers } from '../../store/usersSlice/usersSlice'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'

function ProductItem({productItem}) {
    const {userToken} = useSelector(selectUsers)
    const {currentLang} = useSelector(selectAppInfo)
    return (
        <div className='product-item'>
            <div className='product-item__text'>
                <div className="top">
                    <h4><Link to={"#"}>{productItem.title}</Link></h4>
                    <p>{productItem.text}</p>
                </div>
                <div className="bottom">
                    <span className='price'>{productItem.price}</span>
                    <Link to={userToken ? `/${currentLang.code}/prices` : `/${currentLang.code}/login`} className='buy-btn'>{productItem.btnText}</Link>
                </div>
            </div>
        </div>
    )
}

export default ProductItem
