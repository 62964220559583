import { ErrorMessage, Form, Formik } from 'formik'
import * as yup from 'yup'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUsers, setUserToken, toggleRegData } from '../../store/usersSlice/usersSlice'
import axios from 'axios'
import { IMAGES } from '../../images/images'
import { ArrowRight } from '../../images/svg'
import ReactInputMask from 'react-input-mask'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../Loader/Loader'
import { selectAppInfo } from '../../store/appInfoSlice/appInfoSlice'

function Registration({ textData }) {
    const [regStep, setRegStep] = useState(1)
    const { currentLang } = useSelector(selectAppInfo)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const validationSchema = yup.object().shape({
        fullName: yup.string().required(textData?.error1),
        birthdate: yup.string().required(textData?.error1),
        email: yup
            .string()
            .email(textData?.error2)
            .required(textData?.error1),
        phone: yup.string().required(textData?.error1),
        password: yup
            .string()
            .typeError(textData?.error3)
            .min(8, textData?.error4)
            .matches(/[0-9]/, textData?.error5)
            .required(textData?.error1),
        passportNum: yup.string().required(textData?.error1),
        question: yup.string().required(textData?.error1),
        serviceType: yup.string(),
        preferredLanguage: yup.string(),
        desiredDate: yup.string(),
        additionalInformation: yup.string(),
        documents: yup.string(),
    })

    const { registrationData } = useSelector(selectUsers)
    const dispatch = useDispatch()

    const validateAndProceed = (validateForm, setFieldTouched, errors) => {
        validateForm().then((errors) => {
            if (Object.keys(errors).length === 0) {
                setRegStep(2)
            } else {
                Object.keys(errors).forEach((field) => setFieldTouched(field, true))
            }
        })
    }

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <section className="registration">
                    <div className="registration__container container">
                        <div className="registration__form">
                            <div className="registration__text">
                                <p className="subtitle">
                                    {textData?.subTitle[0]} <span>{textData?.subTitle[1]}</span>{' '}
                                </p>
                                <h2>{textData?.title}</h2>
                                <p>{textData?.desc}</p>
                            </div>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    fullName: registrationData?.fullName || '',
                                    birthdate: registrationData?.birthdate || '',
                                    email: registrationData?.email || '',
                                    phone: registrationData?.phone || '',
                                    password: registrationData?.password || '',
                                    passportNum: registrationData?.passportNum || '',
                                    question: registrationData?.question || '',
                                    serviceType: registrationData?.serviceType || '',
                                    preferredLanguage: registrationData?.preferredLanguage || '',
                                    desiredDate: registrationData?.desiredDate || '',
                                    additionalInformation: registrationData?.additionalInformation || '',
                                    documents: registrationData?.documents || ''
                                }}
                                onSubmit={async (values, { resetForm }) => {
                                    setIsLoading(true)
                                    await axios
                                        .post('https://nordicconnect.pro/api/user/register', {
                                            ...values,
                                            desiredDate: new Date()
                                        })
                                        .then((res) => {
                                            if (res.data._id) {
                                                axios
                                                    .post(`https://nordicconnect.pro/api/auth/login`, {
                                                        email: values.email,
                                                        password: values.password
                                                    })
                                                    .then((res) => {
                                                        dispatch(setUserToken({ token: res.data.access_token }))
                                                        setIsLoading(false)
                                                        navigate(`/${currentLang.code}/prices`)
                                                    })
                                            }
                                        })
                                }}
                                validateOnBlur
                                validationSchema={validationSchema}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    validateForm,
                                    setFieldTouched,
                                }) => (
                                    <Form autoComplete="off" onSubmit={handleSubmit}>
                                        {regStep === 1 ? (
                                            <>
                                                <label className="input-text">
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        name="fullName"
                                                        autoComplete="off"
                                                        value={values.fullName}
                                                        style={{
                                                            border:
                                                                errors.fullName && touched.fullName
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset'
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input1}
                                                    />
                                                    <ErrorMessage name="fullName" component="span" className="error-message" />
                                                </label>
                                                <label className="input-text">
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        name="passportNum"
                                                        autoComplete="off"
                                                        value={values.passportNum}
                                                        style={{
                                                            border:
                                                                errors.passportNum && touched.passportNum
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset'
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input2}
                                                    />
                                                    <ErrorMessage name="passportNum" component="span" className="error-message" />
                                                </label>
                                                <label className="input-text">
                                                    <ReactInputMask
                                                        mask={'99.99.9999'}
                                                        type="text"
                                                        className="input"
                                                        name="birthdate"
                                                        value={values.birthdate}
                                                        autoComplete="off"
                                                        style={{
                                                            border:
                                                                errors.birthdate && touched.birthdate
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset'
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input3}
                                                    />
                                                    <ErrorMessage name="birthdate" component="span" className="error-message" />
                                                </label>
                                                <label className="input-text">
                                                    <input
                                                        type="email"
                                                        className="input"
                                                        name="email"
                                                        autoComplete="off"
                                                        value={values.email}
                                                        style={{
                                                            border:
                                                                errors.email && touched.email
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset'
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input4}
                                                    />
                                                    <ErrorMessage name="email" component="span" className="error-message" />
                                                </label>
                                                <label className="input-text">
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        name="phone"
                                                        autoComplete="off"
                                                        value={values.phone}
                                                        style={{
                                                            border:
                                                                errors.phone && touched.phone
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset'
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input5}
                                                    />
                                                    <ErrorMessage name="phone" component="span" className="error-message" />
                                                </label>
                                                <label className="input-text">
                                                    <input
                                                        type="password"
                                                        className="input"
                                                        name="password"
                                                        autoComplete="off"
                                                        value={values.password}
                                                        style={{
                                                            border:
                                                                errors.password && touched.password
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset'
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input6}
                                                    />
                                                    <ErrorMessage name="password" component="span" className="error-message" />
                                                </label>
                                                <label className="input-text">
                                                    <textarea
                                                        className="input"
                                                        name="question"
                                                        autoComplete="off"
                                                        value={values.question}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        style={{
                                                            border:
                                                                errors.question && touched.question
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset'
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input7}
                                                    ></textarea>
                                                </label>
                                                <label className="input-checkbox">
                                                    <input type="checkbox" />
                                                    <span>
                                                        <svg width={18} height={15} viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 7L6.33333 13L17 1" stroke="white" strokeWidth="1.37143" strokeMiterlimit={10} strokeLinecap="square" />
                                                        </svg>
                                                    </span>
                                                    <p>{textData?.input8}</p>
                                                </label>
                                                <div className="registration__buttons">
                                                    <button
                                                        type="button"
                                                        onClick={() => validateAndProceed(validateForm, setFieldTouched, errors)}
                                                    >
                                                        {textData?.input9}
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <label className="input-text">
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        name="serviceType"
                                                        value={values.serviceType}
                                                        autoComplete="off"
                                                        style={{
                                                            border:
                                                                errors.serviceType && touched.serviceType
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset',
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input10}
                                                    />
                                                    <ErrorMessage name="serviceType" component="span" className="error-message" />
                                                </label>
                                                <label className="input-text">
                                                    <input
                                                        type="text"
                                                        className="input"
                                                        name="preferredLanguage"
                                                        value={values.preferredLanguage}
                                                        autoComplete="off"
                                                        style={{
                                                            border:
                                                                errors.preferredLanguage && touched.preferredLanguage
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset',
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input11}
                                                    />
                                                    <ErrorMessage name="preferredLanguage" component="span" className="error-message" />
                                                </label>
                                                <label className="input-text">
                                                    <ReactInputMask
                                                        mask={"99.99.9999"}
                                                        type="text"
                                                        className="input"
                                                        name="desiredDate"
                                                        value={values.desiredDate}
                                                        autoComplete="off"
                                                        style={{
                                                            border:
                                                                errors.desiredDate && touched.desiredDate
                                                                    ? '1px solid #DC4747'
                                                                    : 'unset',
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input12}
                                                    />
                                                    <ErrorMessage name="desiredDate" component="span" className="error-message" />
                                                </label>
                                                <label className="input-text">
                                                    <input
                                                        className="input"
                                                        name="additionalInformation"
                                                        value={values.additionalInformation}
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            dispatch(toggleRegData({ key: e.target.name, value: e.target.value }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder={textData?.input13}
                                                    />
                                                </label>
                                                <label className="input-file">
                                                    <input type="file" name="" id="" />
                                                    <div className="files">
                                                        <p></p>
                                                    </div>
                                                    <span className="btn">{textData?.input15}</span>
                                                </label>
                                                <div className="registration__buttons">
                                                    <button type="button" onClick={() => setRegStep(1)}>
                                                        {textData?.input16}
                                                    </button>
                                                    <button type="submit">{textData?.input17}</button>
                                                </div>
                                            </>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                            <div className="registration__bottom">
                                <Link to={`/${currentLang.code}/login`}>{textData?.loginLink[0]} <span>{textData?.loginLink[1]}</span> <ArrowRight color={"#C99E06"} /></Link>
                            </div>
                        </div>
                        <div className="registration__img">
                            <img src={IMAGES.strategiesImg1} alt="img" />
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}

export default Registration
