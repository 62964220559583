export const IMAGES = {
    logo: require('./img/logo.png'),
    footerLogo: require('./img/footer-logo.png'),
    mainImg1: require('./img/mainimg1.png'),
    mainImg2: require('./img/mainimg2.png'),
    mainImg3: require('./img/Rectangle 56.png'),
    mainImg4: require('./img/Rectangle 59.png'),
    mainImg5: require('./img/Rectangle 57.png'),
    realEstateImg: require('./img/realEstateimg.png'),
    realEstateImg2: require('./img/Frame 115.png'),
    realEstateImg4: require('./img/estatimg10.png'),
    mapImg: require('./img/layer.png'),
    strategiesImg1: require('./img/Frame 138-2.png'),
    strategiesImg2: require('./img/Frame 140.png'),
    strategiesImg3: require('./img/Frame 141.png'),
    strategiesImg4: require('./img/Frame 138.png'),
    strategiesImg5: require('./img/Frame 141dqw.png'),
    strategiesImg6: require('./img/stimg.png'),
    homeImg: require('./img/Rectangle 227.png'),
    realEstateImg3: require('./img/aboutimg.png'),
    taxImg1: require('./img/taximg1.png'),
    taxImg2: require('./img/taximg2.png'),
    banner: require('./img/banner.png'),
    casesImg1: require('./img/cases1.png'),
    casesImg2: require('./img/cases2.png'),
    casesImg3: require('./img/cases3.png'),
    casesImg4: require('./img/cases4.png')
}